import request from '@/utils/request'


// 查询用户-实名认证（主播认证）列表
export function listUserCertification(query) {
  return request({
    url: '/platform/user-certification/list',
    method: 'get',
    params: query
  })
}

// 查询用户-实名认证（主播认证）分页
export function pageUserCertification(query) {
  return request({
    url: '/platform/user-certification/page',
    method: 'get',
    params: query
  })
}

// 查询用户-实名认证（主播认证）详细
export function getUserCertification(data) {
  return request({
    url: '/platform/user-certification/detail',
    method: 'get',
    params: data
  })
}

// 新增用户-实名认证（主播认证）
export function addUserCertification(data) {
  return request({
    url: '/platform/user-certification/add',
    method: 'post',
    data: data
  })
}

// 修改用户-实名认证（主播认证）
export function updateUserCertification(data) {
  return request({
    url: '/platform/user-certification/edit',
    method: 'post',
    data: data
  })
}

// 删除用户-实名认证（主播认证）
export function delUserCertification(data) {
  return request({
    url: '/platform/user-certification/delete',
    method: 'post',
    data: data
  })
}
