<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>

    <a-card>
      <detail-list title="公会信息" style="width: 1500px">
        <detail-list-item term="公会执照" style="width: 100%"><img style="width: 60px;height: 60px;" preview="人像面" :src="form.guildLicense" v-if="form.guildLicense != null && form.guildLicense != ''"></detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="结算账号（银行卡号）" style="width: 100%">{{ form.settlementAccount }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="结算开户名" style="width: 100%">{{ form.accountName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="结算银行支行名稱" style="width: 100%">{{ form.branchName }}</detail-list-item>
        <a-divider style="margin-bottom: 2px"/>
        <detail-list-item term="结算开户银行所在地" style="width: 100%">{{ form.branchAdress }}</detail-list-item>
      </detail-list>
      <a-divider style="margin-bottom: 32px"/>

    </a-card>
  </a-drawer>
</template>

<script>
import { getUserCertification } from '@/api/platform/userCertification'
import DetailList from '@/components/tools/DetailList'
const DetailListItem = DetailList.Item;

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    DetailList,
    DetailListItem
  },
  data () {
    return {
      submitLoading: false,
      showVer: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        realName: null,

        idCard: null,

        portraitPlane: null,

        emblemPlane: null,

        isSociety: null,

        guildLicense: null,

        settlementAccount: null,

        accountName: null,

        branchName: null,

        branchAdress: null,

        userId: null,

        refusalCause: null,

        applicationStatus: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    changeStatus(status){
      if(status == 1){
        this.showVer = false;
      }
      if(status == 2){
        this.showVer = true;
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        realName: null,
        idCard: null,
        portraitPlane: null,
        emblemPlane: null,
        isSociety: null,
        guildLicense: null,
        settlementAccount: null,
        accountName: null,
        branchName: null,
        branchAdress: null,
        userId: null,
        refusalCause: null,
        applicationStatus: 0,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserCertification({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '详情'
      })
    },
  }
}
</script>
<style>
.ant-divider-horizontal {
  display: none;
}
</style>